<template>
  <p>If you or your organization would like to sponsor 56 Free School Street, please contact us at <a href="mailto:56freeschoolstreet@gmail.com">56freeschoolstreet@gmail.com</a>. Thank you!</p>
</template>

<script>
export default {

}
</script>

<style>

</style>