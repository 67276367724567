<template>
    <div class="card card-body mb-2">
        <slot />
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>